.header-menu{
    display: none;
}

.sidebar-animated{
    transition: all ease-in-out 500ms;
}

.header-menu{
    /* display: none; */
}

.hoverable-header-menu:hover .header-menu{
    display: block;
    background: #000;
}

.hoverable-header-menu p:hover{
    color: black !important;
}

.form-input{
    border: 0px;
    border-bottom: 1px solid lightgray;
}

@media (max-width: 700px) {
    .header-menu{
        display: block !important;
    }
    .header-tabs{
        display: none !important;
    }
}

