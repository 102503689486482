input:focus {
    outline: none;
}

.weight300 {
    font-weight: 300;
}

.weight500 {
    font-weight: 500;
}

.weight600 {
    font-weight: 600;
}

.weight700 {
    font-weight: 700;
}

.weight900 {
    font-weight: 900;
}

.size12 {
    font-size: 12px;
}

.size14 {
    font-size: 14px;
}

.size16 {
    font-size: 16px;
}

.size18 {
    font-size: 18px;
}

.size20 {
    font-size: 20px;
}

.size22 {
    font-size: 22px;
}

.size24 {
    font-size: 24px;
}

.size26 {
    font-size: 26px;
}

.size30 {
    font-size: 30px;
}

.size28 {
    font-size: 28px;
}